import {getStorage} from 'firebase/storage';
import {getFirestore} from "firebase/firestore";
import {getAuth} from "firebase/auth";
import {initializeApp} from "firebase/app"

import {connectFunctionsEmulator, getFunctions} from "firebase/functions";

const app = initializeApp({
  apiKey: "AIzaSyC0JiM5T3-fRzEBjgmAxa325Og8BXpdFPY",
  authDomain: "soberjamie.firebaseapp.com",
  projectId: "soberjamie",
  storageBucket: "soberjamie.appspot.com",
  messagingSenderId: "1036716393857",
  appId: "1:1036716393857:web:7e2e36b4e8f4c894df5480"
});

export const functions = getFunctions(app);

// NB THIS SHOULD NOT BE USED IN PRODUCTION.
if (process.env.NODE_ENV === "development") {
  console.warn("Running firebase functions in the emulator.");
  connectFunctionsEmulator(functions, "localhost", 5000);
}
// DO NOT USE THIS IN PRODUCTION.

export const db = getFirestore(app);
export const storage = getStorage(app);
export const auth = getAuth(app);
