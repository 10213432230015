import './App.css';
import {Success} from "./Components/Success";
import {SomethingWentWrong} from "./Components/SomethingWentWrong";
import {Payment} from "./Components/Payment";

// import react router dom
import {BrowserRouter, Route, Routes} from "react-router-dom";

// import react stripe js
import {loadStripe} from '@stripe/stripe-js';
import {Elements} from '@stripe/react-stripe-js';

const stripePublishable = "pk_live_eFZWtfRGeWbUpStslmiSKphv004jXEfZhd";

// const stripePublishableTest = "pk_test_E5lx3CbYaQcpOxwHlFNbSfWo00GlqexrAx";

function MainComponent() {
  return (
    <>
      <h1>
        soberjamie.com
      </h1>
      <h3>£4.50 Donation</h3>
      {/*<CardContent>*/}
      {/*  Your donation will be split three ways equally to the Motor Neurone Disease Association, the British Heart*/}
      {/*  Foundation and Alzheimer's Research UK*/}
      {/*</CardContent>*/}
      <Routes>
        <Route path={"/"} element={<Payment/>}/>
        <Route path={"/success"} element={<Success/>}/>
        <Route path={"/oops"} element={<SomethingWentWrong/>}/>
      </Routes>
    </>
  );
}

function App() {
  const stripePromise = loadStripe(stripePublishable);

  return (
    <div className="App-header">
      <BrowserRouter>
        <Elements stripe={stripePromise}>
          <MainComponent/>
        </Elements>
      </BrowserRouter>
    </div>
  );
}

export default App;
