import {Button} from "@mui/material";
import React from "react";

export function SomethingWentWrong() {
  return (
    <div>
      <h1>Something went wrong. Please try again, or on another device. You have not been charged. </h1>
      <Button size={"large"} variant={"contained"} onClick={() => window.location.href = "/"}>Try again?</Button>
    </div>
  );
}