import {PaymentRequestButtonElement, useElements, useStripe} from "@stripe/react-stripe-js";
import React, {useEffect, useState} from "react";
import {httpsCallable} from "firebase/functions";
import {functions} from "../InitFirebase";
import {PaymentIntent} from "@stripe/stripe-js";
import {LoadingPaymentButton} from "./LoadingPaymentButton";

export function Payment() {
  const stripe = useStripe();
  const elements = useElements();
  const [paymentRequest, setPaymentRequest] = useState<any | undefined>();
  const [canMakePayment, setCanMakePayment] = useState<boolean>(false);

  // Create payment intent for beer donation produc
  useEffect(() => {
    console.log("stripe", stripe);
    console.log("elements", elements);
    if (!stripe) {
      return;
    }
    const pr = stripe.paymentRequest({
      country: "GB",
      currency: "gbp",
      total: {
        label: "A donation to an awesome cause ❤️🔥",
        amount: 450,
      },
    });

    if (pr) {
      console.log("PaymentRequest was not null! :D", pr);
      pr.canMakePayment().then((result) => {
        if (result) {
          setPaymentRequest(pr);
          setCanMakePayment(true);
        } else {
          console.log("payment request result was null!");
        }
      });

      pr.on("paymentmethod", async (e) => {
        console.log("paymentmethod event", e);
        const getBeerPI = httpsCallable(functions, "getBeerPI");
        getBeerPI().then(async (result) => {
          const {client_secret} = result.data as PaymentIntent;
          const clientSecret = client_secret as string;
          console.log("client_secret", client_secret);

          const {error} = await stripe.confirmCardPayment(
            clientSecret, {
              payment_method: e.paymentMethod.id
            }, {
              handleActions: true,
            });
          if (error) {
            console.log("the payment failed you IDIOT")
            e.complete("fail");
            pr.abort();
            window.location.href = "/oops";
            return;
          }
          e.complete("success");
          // redirect to success page
          window.location.href = "/success";
        });
      });
    }
  }, [stripe, elements]);

  return (
    <>
      <div className={"StripeCardPayment"}>
        {canMakePayment ? <PaymentRequestButtonElement options={{paymentRequest}}/> : <LoadingPaymentButton/>}
      </div>
    </>
  );
}